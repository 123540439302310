// External dependencies
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

// Local dependencies
import AboutApp from '../components/website/aboutApp/AboutApp';
import Layout from '../shared/ui/layout/WebLayout';
import SEO from '../shared/ui/layout/seo';

export default function Home({ data }) {
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = data;

  const { t } = useI18next();

  return (
    <Layout>
      <SEO title={t('mainPageTitle')} description={t('mainPageContent')} />
      <AboutApp />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
