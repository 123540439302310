// External dependencies
import { Col, Container, Div, Row, Text } from 'atomize';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Local dependencies
import { updateOneSignalExternalUserId } from '../../../entities/notification/connectOneSignal';
import QrCodeText from '../../../svg/QrCodeText';
import QrCodeTextEn from '../../../svg/QrCodeTextEn';
import QrCodeTextKy from '../../../svg/QrCodeTextKy';
import SecurityIcon from '../../../svg/SecurityIcon';
import DownloadApp from '../downloadApp/DownloadApp';
import DownloadAppQRCode from '../downloadAppQRCode/DownloadAppQRCode';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import InfoBlock from '../infoBlock/InfoBlock';
import InfoCards from '../infoCard/InfoCards';
import MainHero from '../mainHero/mainHero';
import { dataReviewsUsers } from '../reviews/DataReview';
import Reviews from '../reviews/Reviews';
import Button from '@shared/ui/button';
import './style.scss';

export default function AboutApp() {
  const { currentUser } = useSelector((state) => state.login);
  const data = useStaticQuery(query);
  const doctorWithSmileIcons = getImage(data?.doctorWithSmileIcons);
  const articlesInApp = getImage(data?.articlesInApp);
  const medcheckId = getImage(data?.medcheckId);
  const medcheckPhone = getImage(data?.medcheckPhone);
  const { language } = useI18next();
  const infoBlockData = [
    {
      title: <Trans>doctorsAndClinics</Trans>,
      subTitle: <Trans>callMakeAppointmentText</Trans>,
      flexDir: 'row',
      image: doctorWithSmileIcons,
      backgroundColor: '#F3F6FA',
    },
    {
      title: <Trans>articlesAndVideos</Trans>,
      subTitle: <Trans>readNewArticlesText</Trans>,
      flexDir: 'row-reverse',
      image: articlesInApp,
      backgroundColor:
        'linear-gradient(90deg, rgba(251, 231, 133, 0.3) 0%, rgba(151, 234, 240, 0.3) 100%)',
    },
  ];
  const infoCardsData = [
    {
      children: <SecurityIcon />,
      bgColor: 'linear-gradient(140.94deg, #222B45 0%, #323F66 100%)',
      height: '30%',
      imgHeight: '60%',
      title: <Trans>insuranceStatus</Trans>,
      text: <Trans>checkStatusText</Trans>,
      titleColor: 'white',
      textColor: 'white',
    },
    {
      children: (
        <Div>
          <GatsbyImage image={medcheckId} alt="image" />
        </Div>
      ),
      bgColor: 'main_bg_color',
      height: '30%',
      imgHeight: '60%',
      title: <Trans>medCard</Trans>,
      text: <Trans>createYourAccountText</Trans>,
    },
  ];

  useEffect(() => {
    updateOneSignalExternalUserId(currentUser?.sub);
  }, []);

  return (
    <>
      <Header />
      <MainHero
        title={<Trans>quickSearch</Trans>}
        description={<Trans>findProperDoctorText</Trans>}
        downloadButtons
      >
        <Div
          rounded="40px"
          bg="linear-gradient(90deg, #8CFAC7 0%, #CFD6FA 100%, #CFD6FA 100%)"
          maxW="590px"
          maxH="410px"
          m="0 auto"
          pos="relative"
          data-aos="fade-left"
          data-aos-duration="500"
        >
          <Div
            pos="absolute"
            bottom="-30px"
            style={{ left: '-80px' }}
            zIndex="2"
            opacity={{ xs: '0', md: '1' }}
            p={{ x: '20px' }}
          >
            <Div
              data-aos="zoom-in"
              data-aos-delay="1100"
              w={{ xs: '150px', sm: '200px' }}
            >
              <DownloadAppQRCode />
            </Div>
          </Div>
          <Div
            pos="absolute"
            bottom="-90px"
            left="-300px"
            opacity={{ xs: '0', xl: '1' }}
          >
            <Div data-aos="zoom-in" data-aos-delay="1200">
              {language === 'en' ? (
                <QrCodeTextEn />
              ) : language === 'ky' ? (
                <Div m={{ l: '-50px' }}>
                  <QrCodeTextKy />
                </Div>
              ) : (
                <QrCodeText />
              )}
            </Div>
          </Div>
          <GatsbyImage
            className="medcheck-phone"
            data-aos="zoom-in"
            data-aos-delay="500"
            style={{
              bottom: '0',
              maxHeight: '460px',
              margin: '0 auto',
              top: '-50px',
            }}
            image={medcheckPhone}
            alt="medcheck-phone"
            objectFit="contain"
          />
          <Div />
        </Div>
      </MainHero>
      <Container>
        {infoBlockData &&
          infoBlockData.map((block, index) => (
            <InfoBlock
              key={index}
              title={block.title}
              subtitle={block.subTitle}
              flexDir={block.flexDir}
              paddingForImageInAbout={index === 1 ? '25px' : '0px'}
              image={block.image}
              backgroundColor={block.backgroundColor}
            />
          ))}
        <Div m={{ b: { xs: '50px', sm: '80px', md: '100px' } }}>
          <InfoCards margin={{ b: '20px' }} data={infoCardsData} />
          <Div
            bg="linear-gradient(118deg, #BDBDCE 0%, #E2E8F0 100%), #F3F6FA;"
            rounded="40px"
            p={{ x: '50px', t: '50px' }}
          >
            <Row>
              <Col size={{ xs: '12', md: '6' }}>
                <Text
                  tag="h2"
                  textSize={{ xs: 'subtitle', md: 'title' }}
                  textWeight="900"
                  m={{ b: '1rem' }}
                  textColor="#fff"
                >
                  <Trans>learnQuick</Trans>
                </Text>
                <Text
                  textSize="medium"
                  textWeight="500"
                  m={{ b: '1rem' }}
                  textColor="#fff"
                >
                  <Trans>platformForPharmacistToLearn</Trans>
                </Text>
                <Div maxW="350px" m={{ b: '2rem' }}>
                  <Link to={`/meducation`}>
                    <Button typeColor="primary">
                      <Trans>btnBanner</Trans>
                    </Button>
                  </Link>
                </Div>
              </Col>
              <Col
                d="flex"
                justify={{ xs: 'center', md: 'end' }}
                align="flex-end"
              >
                <GatsbyImage
                  image={getImage(data?.medcheckPhoneForMeducation)}
                  alt="Medcheck App"
                  objectFit="contain"
                  style={{
                    maxHeight: '350px',
                    width: 'auto',
                    maxWidth: '400px',
                  }}
                />
              </Col>
            </Row>
          </Div>
        </Div>
      </Container>
      <Reviews
        title={<Trans>whatSayAboutUsText</Trans>}
        description={<Trans>usersReview</Trans>}
        dataReviews={dataReviewsUsers}
      />
      <main>
        <Container>
          <DownloadApp />
        </Container>
      </main>
      <Footer />
    </>
  );
}

export const query = graphql`
  query {
    doctorWithSmileIcons: file(relativePath: { eq: "doctor-with-smile.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    articlesInApp: file(relativePath: { eq: "articles-in-app.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    medcheckId: file(relativePath: { eq: "medcheck-id.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    medcheckPhone: file(relativePath: { eq: "medcheck-phone.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    medcheckPhoneForMeducation: file(
      relativePath: { eq: "iPhone-for-main-page.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
