// External dependensies
import React from 'react';

// Local dependensies
import { IconProps } from './types';

export default function SecurityIcon({ width = 288, height = 282 }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 288 282"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M284.503 42.4429C282.298 40.6589 279.393 39.9699 276.621 40.5637C276.536 40.5819 267.93 42.3915 254.451 42.3915C227.291 42.3915 187.168 35.4525 150.265 2.3959C146.698 -0.798634 141.303 -0.798634 137.735 2.3959C100.833 35.4512 60.7098 42.3909 33.5489 42.3915C20.0667 42.3915 11.4655 40.5819 11.4017 40.5681C8.62247 39.9587 5.71993 40.642 3.50421 42.426C1.28849 44.2093 0 46.9014 0 49.7465C0 139.497 27.7382 194.77 51.0079 225.334C82.8157 267.113 119.901 282 144 282C168.099 282 205.185 267.113 236.992 225.334C260.262 194.77 288 139.496 288 49.7465C288 46.909 286.708 44.2262 284.503 42.4429Z"
        fill="#131826"
      />
      <path
        d="M126.489 200V81H162.511V140.5V200H126.489ZM85 158.511V122.489H204V158.511H85Z"
        fill="url(#paint0_linear_4171_131981)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4171_131981"
          x1="85"
          y1="81"
          x2="204"
          y2="200"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00D68F" />
          <stop offset="1" stopColor="#00D68F" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
